<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-file</v-icon>
        {{ $vuetify.lang.t("$vuetify.company.files") }}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption">
      <br />
      <v-btn float-right small @click="fileUploadPopup = true">
        <v-icon class="mr-3">mdi-file-upload</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.upload_file") }}
      </v-btn>
      <br />
      <v-list
        two-line
        style="max-height:350px; overflow:hidden; overflow-y: auto;"
      >
        <template v-for="file in filesNew">
          <v-list-item :key="file.id" class=" mr-n1">
            <v-list-item-content>
              <a :href="file.url" :download="file.filename"
                ><v-list-item-title v-html="file.filename"></v-list-item-title
              ></a>
              <v-list-item-subtitle v-html="file.date"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-1" @click="remove(file.id)"
                >mdi-delete</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <FileUpload
        :fileUploadPopup="fileUploadPopup"
        v-on:new-file="fileAdded"
        v-on:closePopup="fileUploadPopup = false"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Api from "@/services/api";
import FileUpload from "@/components/manager/popup/FileUpload";
export default {
  components: {
    FileUpload,
  },
  props: ["files", "orgID"],
  data: function() {
    return {
      filesNew: [],
      fileUploadPopup: false,
    };
  },
  watch: {
    files: function(newVal) {
      this.filesNew = newVal;
    },
  },
  methods: {
    fileAdded: function(file) {
      this.filesNew.push(file);
    },
    remove: function(fid) {
      Api.file("remove", fid).then(() => {
        // var index = this.filesNew
        //   .map((x) => {
        //     return x.Id;
        //   })
        //   .indexOf(fid);

        // this.filesNew.splice(index, 1);
        this.filesNew = this.filesNew.filter((x) => {
          return x.id != fid;
        });
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    },
  },
};
</script>
