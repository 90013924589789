<template>
  <v-dialog v-model="dialogState" persistent max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-pencil</v-icon>
          {{$vuetify.lang.t('$vuetify.action.add_transaction')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text style="min-height:420px;">
        <v-form ref="contactEditForm">
          <v-row align="start">
            <v-col md="6" v-if="newTransaction.payment_type == 'membership'">
              <div class="v-input v-input--hide-details theme--light v-text-field">
                <div class="v-input__control">
                  <div class="v-input__slot" style="width:100" append-icon="mdi-magnify">
                    <div class="v-text-field__slot">
                      <datepicker
                        v-model="newTransaction.payed_from"
                        :language="dateLanguage()"
                        :monday-first="true"
                        :placeholder="$vuetify.lang.t('$vuetify.company.payed_from')+' *'"
                        :disabled-dates="disabledDatesTO"
                        :error="validator.payed_from.error_show"
                        :error-messages="validator.payed_from.error_msg"
                        :highlighted="currentDate"
                      ></datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col md="6" v-if="newTransaction.payment_type == 'membership'">
              <div class="v-input v-input--hide-details theme--light v-text-field">
                <div class="v-input__control">
                  <div class="v-input__slot" style="width:100%" append-icon="mdi-magnify">
                    <div class="v-text-field__slot">
                      <datepicker
                        v-model="newTransaction.payed_to"
                        :language="dateLanguage()"
                        :monday-first="true"
                        :placeholder="$vuetify.lang.t('$vuetify.company.payed_to')+' *'"
                        :disabled-dates="disabledDatesTO"
                        :error="validator.payed_to.error_show"
                        :error-messages="validator.payed_to.error_msg"
                        :highlighted="currentDate"
                      ></datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col md="6" v-if="newTransaction.payment_type == 'barcode'">
              <v-text-field
                v-model="newTransaction.barcode_count"
                :label="$vuetify.lang.t('$vuetify.company.barcode_count')+' *'"
                type="number"
                :error="validator.barcode_count.error_show"
                :error-messages="validator.barcode_count.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newTransaction.amount"
                :label="$vuetify.lang.t('$vuetify.tables.amount')+' *'"
                type="number"
                :error="validator.amount.error_show"
                :error-messages="validator.amount.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col md="6" v-if="newTransaction.payment_type == 'membership'">
              <v-text-field
                v-model="newTransaction.next_payment_amount"
                :label="$vuetify.lang.t('$vuetify.tables.next_payment_amount')+' *'"
                type="number"
                :error="validator.next_payment_amount.error_show"
                :error-messages="validator.next_payment_amount.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea :label="$vuetify.lang.t('$vuetify.company.comment')"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>

        <v-btn @click="addNewTransaction" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.add')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import TransactionHelper from "@/helpers/transaction";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";

export default {
  components: {
    Datepicker
  },
  data: function() {
    return {
      currentDate:{ dates: [new Date(new Date().toISOString().slice(0, 10))]},
      type: false,
      dialogState: false,
      newTransaction: TransactionHelper.newItemModel(),
      validator: TransactionHelper.validatorModel(),
      payed_from: null,
      payed_to: null,
      disabledDatesFrom: {
        from: ""
      },
      disabledDatesTO: {
        to: ""
      }
    };
  },
  computed: {
    paymentTypes() {
      return TransactionHelper.itemTypes();
    }
  },
  watch: {
    popupAddTransaction: function(newVal) {
      this.dialogState = newVal;
      this.newTransaction = TransactionHelper.newItemModel();
      this.newTransaction.payment_type = this.$parent.trType;
    },
    "newTransaction.payment_type": function(newType) {
      if (newType == "membership") {
        this.newTransaction.barcode_count = null;
      }
      if (newType == "barcode") {
        this.newTransaction.payed_from = null;
        this.newTransaction.payed_to = null;
      }
      this.validator = TransactionHelper.validateForm(this.newTransaction);
    },
    "newTransaction.payed_from": function() {
      this.validator = TransactionHelper.validateForm(this.newTransaction);
    },
    "newTransaction.payed_to": function() {
      this.validator = TransactionHelper.validateForm(this.newTransaction);
    },
    "newTransaction.barcode_count": function(newVal) {
      this.newTransaction.amount = newVal * 35;
    }
  },
  methods: {
    dateLanguage() {
      var dateLocales = new Array();
      dateLocales["en"] = en;
      dateLocales["ka"] = ge;
      return dateLocales[this.$i18n.locale];
    },
    validateForm() {
      this.validator = TransactionHelper.validateForm(this.newTransaction);
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closeAddTransactionPopup");
      this.validator = TransactionHelper.validatorModel();
      this.newTransaction = TransactionHelper.newItem;
    },
    addNewTransaction() {
      var d = this.newTransaction;
      d["company_id"] = this.$route.params.id;
      Api.payment("create", d).then(() => {
        this.$parent.reloadStats();
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.create_success"));
        this.closePopup();
      });
    }
  },
  props: ["popupAddTransaction"]
};
</script>
<style scoped>
.vdp-datepicker {
  width: 100%;
}
</style>