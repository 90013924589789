<template>
  <v-dialog v-model="dialogState" persistent max-width="800">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-file-import</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.upload_file") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <v-file-input
          show-size
          truncate-length="50"
          :placeholder="$vuetify.lang.t('$vuetify.action.choose_file')"
          accept=".xls,.xlsx,.doc,.docx,.txt,.csv"
          ref="file"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="cancelEdit">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>

        <v-btn @click="upload()">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.upload") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false,
    };
  },
  watch: {
    fileUploadPopup: function(newVal) {
      this.dialogState = newVal;
    },
  },
  methods: {
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
    },
    upload() {
      let f = this.$refs.file.$refs.input.files[0];
      let formData = new FormData();
      formData.append('file', f);
      formData.append('company_id', this.$route.params.id)
      Api.file('upload', formData).then(({data}) => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.manager.imported"));
          this.$emit('new-file',data)
          this.closePopup();
      });
    },
    cancelEdit() {
      this.closePopup();
    },
  },
  props: ["fileUploadPopup"],
};
</script>
