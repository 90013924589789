<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-cash</v-icon>
        GS1 Georgia
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption" v-if="this.info.payment">
      <v-row v-if="!limited">
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.membership_payments")
        }}</v-col>
        <v-col class="col-6"
          >{{ this.info.payment.membership }}
          {{ $vuetify.lang.t("$vuetify.company.gel") }}</v-col
        >
      </v-row>
      <v-row v-if="!limited">
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.next_payment_amount")
        }}</v-col>
        <v-col class="col-6">{{ this.info.next_payment_amount }}</v-col>
      </v-row>

      <v-row>
        <v-col md="6" v-if="!limited">
          <v-btn
            small
            @click="$emit('add-transaction', 'membership')"
            class="mr-2 mb-3"
          >
            <v-icon small class="mr-2 mt-n1">mdi-cash-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.add_transaction") }}
          </v-btn>
          <v-btn
            small
            @click="$emit('create-invoice', 'membership')"
            class="mr-2 mb-3"
          >
            <v-icon small class="mr-2 mt-n1">mdi-file-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.invoice.create") }}
          </v-btn>
        </v-col>
        <v-col md="6">
          <v-btn
            small
            :to="'/'+userType+'/finances/membership/' + companyID"
            class="mr-2 mb-3"
            v-if="!limited"
          >
            <v-icon small class="mr-2 mt-n1">mdi-cash-multiple</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.view_transactions") }}
          </v-btn>
          <v-btn
            small
            :to="'/'+userType+'/invoices/membership/' + companyID"
            class="mr-2 mb-3"
          >
            <v-icon small class="mr-2 mt-n1">mdi-file-eye</v-icon>
            {{ $vuetify.lang.t("$vuetify.invoice.view") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  data: function() {
    return {
      info: this.stats,
      userType: this.limited ? 'customer' : 'manager'
    };
  },
  methods: {
    addTransaction() {
      this.$parent.dialogAdd = true;
    },
  },
  watch: {
    stats: function(newVal) {
      this.info = newVal;
    },
  },
  props: ["stats", "companyID", "companyName", "limited"],
};
</script>
<style scoped>
@media (max-width: 1801px) {
  .v-btn.v-size--small {
    font-size: 0.58rem;
  }
  .v-btn:not(.v-btn--round).v-size--small {
    padding: 0 3.888889px;
  }
}
</style>
