import vm from '@/main.js'
import * as HelperModel from '@/models/invoice.js'

class InvoiceHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(amount, barcode_count, itype, comment) {
        var validator = this.validatorModel()
        if (amount === null || amount.length <= 0) {
            validator.amount.error_show = true;
            validator.amount.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.amount.error_show = false;
            validator.amount.error_msg = null;
            validator.hasError = false;
        }

        if (itype == 'barcode') {
            if (barcode_count === null || barcode_count.length <= 0) {
                validator.barcode_count.error_show = true;
                validator.barcode_count.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.barcode_count.error_show = false;
                validator.barcode_count.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        if(itype != 'barcode') {
            if (comment === null || comment.length <= 0) {
                validator.comment.error_show = true;
                validator.comment.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.comment.error_show = false;
                validator.comment.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        return validator;
    }

}

const InvoiceHelper = new InvoiceHelpers
export default InvoiceHelper