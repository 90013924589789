<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.change_status") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3">
        <h3>{{$vuetify.lang.t('$vuetify.action.change_status_confirm')}}</h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.confirmStatusUpdatePopup=false">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="$parent.statusConfirmed(status)">
          <v-icon class="mr-3">mdi-check</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false,
      status: '',
    };
  },
  watch: {
    confirmStatusUpdate: function(newVal) {
      this.dialogState = newVal;
      this.status = newVal
    }
  },
  props: ["confirmStatusUpdate"]
};
</script>