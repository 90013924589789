<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-comment-text</v-icon>
        {{$vuetify.lang.t('$vuetify.company.comments')}}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption">
      <v-textarea v-model="comm"></v-textarea>
      <v-spacer class="mt-5 mb-5"></v-spacer>
      <v-btn small @click="saveComment()" :loading="saveCcommentLoading">
        <v-icon class="mr-3">mdi-content-save</v-icon>
        {{$vuetify.lang.t('$vuetify.action.save')}}
      </v-btn>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Api from "@/services/api";
export default {
  props: ["comment", "orgID"],
  data: function() {
    return {
      comm: false,
      saveCcommentLoading: false
    };
  },
  watch: {
    comment: function(newVal) {
      this.comm = newVal;
    }
  },
  methods: {
    saveComment() {
      this.saveCcommentLoading = true;
      let c = {
        id: this.orgID,
        comment: this.comm
      };
      Api.company("update", c).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.saveCcommentLoading = false;
      });
    }
  }
};
</script>