<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-cart</v-icon>
        GS1 Solutions
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption" v-if="this.info.payment">
      <v-row v-if="!limited">
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.barcode_payments")
        }}</v-col>
        <v-col class="col-6"
          >{{ this.info.payment.barcode }}
          {{ $vuetify.lang.t("$vuetify.company.gel") }}</v-col
        >
      </v-row>

      <v-row>
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.assigned_barcodes")
        }}</v-col>
        <v-col class="col-xs-6">{{ this.info.assigned_barcodes }}</v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.registered_products")
        }}</v-col>
        <v-col class="col-xs-6">{{ this.info.registered_products }}</v-col>
      </v-row>

      <v-row  class="mt-5 pl-3 btns-layer" v-if="!limited">
        <v-col sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small @click="$emit('add-transaction', 'barcode')">
            <v-icon small class="mr-2 ">mdi-cash-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.add_transaction") }}
          </v-btn>
        </v-col>
        <v-col  xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small :to="'/' + userType + '/finances/barcode/' + companyID" v-if="!limited" >
            <v-icon small class="mr-2 ">mdi-cash-multiple</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.view_transactions") }}
          </v-btn>
        </v-col>
        <v-col xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small @click="$emit('add-product')">
            <v-icon small class="mr-2">mdi-cart-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.add_product") }}
          </v-btn>
        </v-col>
        <v-col xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small @click="$emit('import-product')">
            <v-icon small class="mr-2 ">mdi-file-import</v-icon>
            {{ $vuetify.lang.t("$vuetify.product.import") }}
          </v-btn>
        </v-col>
        <v-col xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small v-if="!limited" :to="'/manager/products/id/' + companyID">
            <v-icon small class="mr-2 ">mdi-cart</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.view_products") }}
          </v-btn>
          <v-btn small v-else :to="'/' + userType + '/products/' + companyID">
            <v-icon small class="mr-2 ">mdi-cart</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.view_products") }}
          </v-btn>
        </v-col>
        <v-col xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small @click="$emit('create-invoice', 'barcode')">
            <v-icon small class="mr-2 ">mdi-file-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.invoice.create") }}
          </v-btn>
        </v-col>
        <v-col xs="12" sm="12" md="6" class="pa-0 mb-5 pr-12">
          <v-btn small v-if="!limited" :to="'/manager/invoices/barcode/' + companyID">
            <v-icon small class="mr-2 ">mdi-file-eye</v-icon>
            {{ $vuetify.lang.t("$vuetify.invoice.view") }}
          </v-btn>
          <v-btn small v-else :to="'/' + userType + '/invoices/barcode/' + companyID">
            <v-icon small class="mr-2 ">mdi-file-eye</v-icon>
            {{ $vuetify.lang.t("$vuetify.invoice.view") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  data: function() {
    return {
      info: this.stats,
      userType: this.limited ? "customer" : "manager",
    };
  },
  methods: {
    addTransaction() {
      this.$parent.dialogAdd = true;
    },
  },
  watch: {
    stats: function(newVal) {
      this.info = newVal;
    },
  },
  props: ["stats", "companyID", "companyName", "limited"],
};
</script>
<style scoped>
@media (max-width: 1510px) {
  .btns-layer div{
    min-width: 100%!important
  }
}
</style>
