<template>
  <v-dialog v-model="dialogState" persistent max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-file-plus</v-icon>
          {{$vuetify.lang.t('$vuetify.invoice.create')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start" class="mt-3">
            <div class="col-xs-12 col-sm-12 col-md-6" v-if="$parent.invoice_type == 'barcode'">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.barcode_count')"
                v-model="newItem.barcode_count"
                type="number"
                :error="validator.barcode_count.error_show"
                :error-messages="validator.barcode_count.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.tables.amount')"
                v-model="newItem.amount"
                type="number"
                :error="validator.amount.error_show"
                :error-messages="validator.amount.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
          <v-row align="start" class="mt-3">
            <v-col>
              <v-autocomplete
                v-model="newItem.template"
                :items="$vuetify.lang.t('$vuetify.invoice_templates')"
                item-text="value"
                item-value="key"
                return-object
                :label="$vuetify.lang.t('$vuetify.company.invoice_template') + ' *'"
                @keyup="validateForm"
                @change="validateForm"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="start" class="mt-3">
            <v-col>
              <v-textarea
                v-model="newItem.comment"
                :label="$vuetify.lang.t('$vuetify.company.comment')"
                :error="validator.comment.error_show"
                :error-messages="validator.comment.error_msg"
                @keyup="validateForm"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="createInvoice" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import InvoiceHelper from "@/helpers/invoice";
export default {
  data: function() {
    return {
      dialogState: false,
      validator: InvoiceHelper.validatorModel(),
      newItem: InvoiceHelper.newItemModel()
    };
  },
  methods: {
    validateForm() {
      this.validator = InvoiceHelper.validateForm(
        this.newItem.amount,
        this.newItem.barcode_count,
        this.$parent.invoice_type,
        this.newItem.comment
      );
    },
    closePopup() {
      this.$emit("closePopup");
    },
    createInvoice() {
      this.newItem['template'] = this.newItem['template']['key'] ? this.newItem['template']['key'] : this.newItem['template'];
      this.newItem['company_id'] = this.$route.params.id;
      this.newItem['invoice_type'] = this.$parent.invoice_type;
      Api.invoice("create", this.newItem)
        .then(() => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.invoice.created")
          );
          this.$parent.reload += 1;
          this.closePopup();
        })
        .catch(error => {
          if (
            error.response.status == 400 &&
            typeof error.response.data.field !== "undefined"
          ) {
            this.validator.username.error_show = true;
            this.validator.username.error_msg = this.$i18n.messages[
              this.$i18n.locale
            ]["$vuetify"]["service_field_errors"][error.response.data.error];
          }
        });
    }
  },
  watch: {
    createInvoicePopup: function(newVal) {
      this.dialogState = newVal;
      this.newItem.invoice_type = this.$parent.invoice_type
      this.newItem = InvoiceHelper.newItemModel()
    },
    "newItem.barcode_count": function(newVal) {
      this.newItem.amount = newVal * 35;
    }
  },
  props: ["createInvoicePopup"]
};
</script>