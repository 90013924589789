<template>
  <div>
    <v-row>
      <v-col class="col-sm-12 col-xs-12 col-md-8">
        <v-card>
          <v-toolbar color="accent" fixed>
            <v-toolbar-title>
              <v-icon color="primary" class="mx-auto iconFix mr-3"
                >mdi-briefcase</v-icon
              >
              {{ $vuetify.lang.t("$vuetify.company.info") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text column style="padding-top:10px">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  id="title-ka"
                  v-model="company.name.ka"
                  :label="$vuetify.lang.t('$vuetify.company.name_ka')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="title-en"
                  v-model="company.name.en"
                  :label="$vuetify.lang.t('$vuetify.company.name_en')"
                  v-on:keypress="latinOnly($event)"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="identity_code"
                  v-model="company.id_code"
                  :label="$vuetify.lang.t('$vuetify.company.identity_code')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="manager_id"
                  v-model="company.manager_id"
                  :label="$vuetify.lang.t('$vuetify.company.manager_id')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="prefix"
                  v-model="company.prefix"
                  :label="$vuetify.lang.t('$vuetify.company.prefix')"
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="gln"
                  v-model="company.gln"
                  :label="$vuetify.lang.t('$vuetify.company.gln')"
                  readonly
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="id"
                  v-model="company.reg_id"
                  :label="$vuetify.lang.t('$vuetify.company.reg_id')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="v-text-field__slot">
                  <label
                    for="id"
                    class="v-label v-label--active theme--light"
                    style="font-size:0.9em;position: absolute;"
                  >
                    {{ $vuetify.lang.t("$vuetify.company.reg_date") }}
                  </label>
                </div>
                <div
                  class="v-input v-input--hide-details theme--light v-text-field"
                >
                  <div class="v-input__control">
                    <div
                      class="v-input__slot"
                      style="width:100"
                      append-icon="mdi-magnify"
                    >
                      <div class="v-text-field__slot">
                        <datepicker
                          v-model="company.reg_date"
                          :language="dateLanguage()"
                          :monday-first="true"
                          :highlighted="currentDate"
                        ></datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="address_ka"
                  v-model="company.address.ka"
                  :label="$vuetify.lang.t('$vuetify.company.address_ka')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="address_en"
                  v-model="company.address.en"
                  :label="$vuetify.lang.t('$vuetify.company.address_en')"
                  v-on:keypress="latinOnly($event)"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'ka'">
                <v-select
                  v-model="selectedCityKA"
                  :items="citiesKA"
                  :label="$vuetify.lang.t('$vuetify.company.city_ka')"
                  item-text="name"
                  item-value="key"
                  @change="cityChange"
                >
                  <template slot="selection" slot-scope="citiesKA">{{
                    citiesKA.item.name
                  }}</template>
                  <template slot="item" slot-scope="citiesKA">{{
                    citiesKA.item.name
                  }}</template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'en'">
                <v-select
                  v-model="selectedCityEN"
                  :items="citiesEN"
                  :label="$vuetify.lang.t('$vuetify.company.city_en')"
                  @change="cityChange"
                  item-text="name"
                  item-value="key"
                >
                  <template slot="selection" slot-scope="citiesEN">{{
                    citiesEN.item.name
                  }}</template>
                  <template slot="item" slot-scope="citiesEN">{{
                    citiesEN.item.name
                  }}</template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedTurnover"
                  :items="turnovers"
                  :label="$vuetify.lang.t('$vuetify.company.yearly_turnover')"
                  @change="turnoverChanged"
                  item-text="name"
                  item-value="key"
                >
                  <template slot="selection" slot-scope="turnovers">{{
                    turnovers.item.name
                  }}</template>
                  <template slot="item" slot-scope="turnovers">{{
                    turnovers.item.name
                  }}</template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'ka'">
                <v-select
                  id="production_main_field_ka"
                  v-model="selectedMainfieldKA"
                  :items="mainfieldKA"
                  :menu-props="{ top: true, offsetY: true }"
                  :label="
                    $vuetify.lang.t('$vuetify.company.production_main_field_ka')
                  "
                  @change="mainFieldChange"
                  item-text="name"
                  item-value="key"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'en'">
                <v-select
                  id="production_main_field_en"
                  v-model="selectedMainfieldEN"
                  :items="mainfieldEN"
                  :menu-props="{ top: true, offsetY: true }"
                  :label="
                    $vuetify.lang.t('$vuetify.company.production_main_field_en')
                  "
                  @change="mainFieldChange"
                  item-text="name"
                  item-value="key"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'ka'">
                <v-select
                  id="production_field_ka"
                  v-model="selectedFieldKA"
                  :items="fieldKA"
                  :menu-props="{ top: true, offsetY: true }"
                  :label="
                    $vuetify.lang.t('$vuetify.company.production_field_ka')
                  "
                  @change="fieldChange"
                  item-text="name"
                  item-value="key"
                  attach
                  chips
                  multiple
                  deletable-chips
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" v-if="$i18n.locale == 'en'">
                <v-select
                  id="production_field_en"
                  v-model="selectedFieldEN"
                  :items="fieldEN"
                  :menu-props="{ top: true, offsetY: true }"
                  :label="
                    $vuetify.lang.t('$vuetify.company.production_field_en')
                  "
                  @change="fieldChange"
                  item-text="name"
                  item-value="key"
                  attach
                  chips
                  multiple
                  deletable-chips
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="postal_code"
                  v-model="company.postal_code"
                  :label="$vuetify.lang.t('$vuetify.company.postal_code')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      id="phone"
                      v-model="company.phone"
                      :label="$vuetify.lang.t('$vuetify.company.phone')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-checkbox
                          v-model="company.phoneVerified"
                          v-on="on"
                        ></v-checkbox>
                      </template>
                      <span>{{
                        $vuetify.lang.t("$vuetify.company.verified")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  id="website"
                  v-model="company.website"
                  :label="$vuetify.lang.t('$vuetify.company.website')"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6"
                ><v-row>
                  <v-col cols="9">
                    <v-text-field
                      id="email"
                      v-model="company.email"
                      :label="$vuetify.lang.t('$vuetify.company.email')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-checkbox
                          v-model="company.emailVerified"
                          v-on="on"
                        ></v-checkbox>
                      </template>
                      <span>{{
                        $vuetify.lang.t("$vuetify.company.verified")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="company.message_locale"
                  :items="$i18n.messages[$i18n.locale].languages"
                  :menu-props="{ top: true, offsetY: true }"
                  :label="
                    $vuetify.lang.t('$vuetify.company.message_locale')
                  "
                  item-text="name"
                  item-value="key"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <div>{{$vuetify.lang.t('$vuetify.company.allowed_messages')}}</div>
                <v-checkbox
                  v-model="company.send_phone"
                  class="float-left mr-5 mt-n1"
                  label="SMS"
                ></v-checkbox>
                <v-checkbox
                  v-model="company.send_email"
                  class="float-left mr-5 mt-n1"
                  label="Email"
                ></v-checkbox>
                <v-checkbox
                  v-model="company.send_message"
                  class="float-left mt-n1"
                  label="Inbox"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              class="ml-3 mb-2 btn btn-success"
              color="#008DBD"
              dark
              @click="savecompany"
            >
              <v-icon class="mr-1">mdi-content-save</v-icon>
              {{ $vuetify.lang.t("$vuetify.action.save_changes") }}
            </v-btn>
            <v-btn small class="ml-3 mb-2 btn-default" @click="resetcompany">
              <v-icon class="mr-1">mdi-close</v-icon>
              {{ $vuetify.lang.t("$vuetify.action.cancel_changes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--right bar !-->
      <v-col class="col-xs-12 col-sm-12 col-md-4">
        <v-row class="mt-n3">
          <v-col class="col-xs-12 col-md-12">
            <v-expansion-panels v-model="panel">
              <CompanyStats
                :companyID="orgID"
                :companyName="company.name"
                :stats="this.company.statistics"
              />
              <Contacts
                :companyID="orgID"
                :contacts="company.contacts"
                v-on:gepir="gepir"
              />
              <Comment :comment="company.comment" :orgID="orgID" />
              <CompanyMembership
                :companyID="orgID"
                :companyName="company.name"
                v-on:add-transaction="addTrPopup"
                v-on:create-invoice="createInvoice"
                v-bind:stats="this.company.statistics"
              />
              <CompanyBarcode
                :companyID="orgID"
                :companyName="company.name"
                v-on:add-transaction="addTrPopup"
                v-on:add-product="dialogAddProduct = true"
                v-on:import-product="dialogImport = true"
                v-on:create-invoice="createInvoice"
                :stats="this.company.statistics"
              />
              <Messages :messages="company.messages" :orgID="orgID" />
              <Files :files="company.media" :orgID="orgID" />
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AddTransaction
      :popupAddTransaction="dialogAdd"
      v-on:closeAddTransactionPopup="dialogAdd = false"
      v-on:editTotalTransactions="editTotalTransactions"
    />
    <AddProduct
      :popupAddProduct="dialogAddProduct"
      v-on:closePopupAddProduct="dialogAddProduct = false"
      v-on:editTotalProducts="editTotalProducts"
    />
    <CreateInvoice
      :createInvoicePopup="createInvoicePopup"
      v-on:closePopup="createInvoicePopup = false"
    />
    <Import
      :importPopup="dialogImport"
      v-on:closeImportPopup="dialogImport = false"
    />
    <UpdateStatus :confirmStatusUpdate="confirmStatusUpdatePopup" />
  </div>
</template>

<script>
import Api from "@/services/api";
import turnoverRanges from "@/modules/turnovers";
import CompanyStats from "@/components/shared/expansion/CompanyStats";
import CompanyBarcode from "@/components/shared/expansion/CompanyBarcode";
import CompanyMembership from "@/components/shared/expansion/CompanyMembership";
import Contacts from "@/components/shared/expansion/Contacts";
import Messages from "@/components/shared/expansion/Messages";
import AddTransaction from "@/components/manager/popup/AddTransaction";
import AddProduct from "@/components/shared/popup/AddProduct";
import Comment from "@/components/manager/expansion/Comment";
import Files from "@/components/manager/expansion/Files";
import CreateInvoice from "@/components/manager/popup/CreateInvoice";
import Import from "@/components/manager/popup/ImportProduct";
import Datepicker from "vuejs-datepicker";
import { en, ge } from "vuejs-datepicker/dist/locale";
import UpdateStatus from "../../components/manager/popup/UpdateStatus.vue";
export default {
  components: {
    CreateInvoice,
    CompanyStats,
    CompanyMembership,
    CompanyBarcode,
    Contacts,
    Messages,
    AddTransaction,
    AddProduct,
    Comment,
    Import,
    Files,
    Datepicker,
    UpdateStatus,
  },
  data: function() {
    return {
      currentDate: { dates: [new Date(new Date().toISOString().slice(0, 10))] },
      dialogImport: false,
      orgID: this.$route.params.id,
      company: {
        name: {
          ka: "",
          en: "",
        },
        address: {
          ka: "",
          en: "",
        },
        statistics: {
          assigned_barcodes: 0,
          debt: 0,
          registered_products: 0,
          status: null,
          reg_date: null,
        },
      },
      companyOriginal: this.company,
      panel: 0,
      citiesKA: [],
      citiesEN: [],
      selectedCityKA: false,
      selectedCityEN: false,
      fieldKA: [],
      fieldEN: [],
      mainfieldEN: [],
      mainfieldKA: [],
      selectedFieldKA: false,
      selectedFieldEN: false,
      selectedMainfieldKA: false,
      selectedMainfieldEN: false,
      selectedTurnover: false,
      currentMessage: {},
      switchStatus: false,
      dialogAdd: false,
      dialogAddProduct: false,
      trType: false,
      createInvoicePopup: false,
      invoice_type: null,
      confirmStatusUpdatePopup: false,
      selectedMessageLocale: null
    };
  },
  computed: {
    turnovers() {
      return turnoverRanges(
        this.$vuetify.lang.t("$vuetify.company.mln"),
        this.$vuetify.lang.t("$vuetify.company.mln_and_more")
      );
    },
  },
  methods: {
    statusConfirmed(s) {
      console.log(s)
      const d = {
        id: this.orgID,
        status: s
      };
      Api.company("update", d).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.confirmStatusUpdatePopup = false;
        this.reloadStats();
      });
    },
    dateLanguage() {
      var dateLocales = new Array();
      dateLocales["en"] = en;
      dateLocales["ka"] = ge;
      return dateLocales[this.$i18n.locale];
    },
    createInvoice(i) {
      this.invoice_type = i;
      this.createInvoicePopup = true;
    },
    addTrPopup(tType) {
      this.trType = tType;
      this.dialogAdd = true;
    },
    gepir(v) {
      v.export = true;
      Api.contact("update", v).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    },
    editTotalProducts() {
      this.company.statistics.registered_products += 1;
    },
    editTotalTransactions(val) {
      const a = 1 * val.amount;
      this.company.statistics.total_payments =
        this.company.statistics.total_payments + a;
      this.company.statistics.debt =
        this.company.statistics.debt - a < 0
          ? this.company.statistics.debt
          : this.company.statistics.debt - a;
    },
    loadLists() {
      let kaCitiesArray = this.$i18n.messages.ka.$vuetify.city;
      let enCitiesArray = this.$i18n.messages.en.$vuetify.city;

      for (var kaKey in kaCitiesArray) {
        this.citiesKA.push({ name: kaCitiesArray[kaKey], key: kaKey });
      }

      for (var enKey in enCitiesArray) {
        this.citiesEN.push({ name: enCitiesArray[enKey], key: enKey });
      }

      let kaFieldsArray = this.$i18n.messages.ka.$vuetify.production_fields;
      let enFieldsArray = this.$i18n.messages.en.$vuetify.production_fields;

      for (var kaFieldKey in kaFieldsArray) {
        this.fieldKA.push({
          name: kaFieldsArray[kaFieldKey],
          key: kaFieldKey,
        });
        this.mainfieldKA.push({
          name: kaFieldsArray[kaFieldKey],
          key: kaFieldKey,
        });
      }

      for (var enFieldKey in enFieldsArray) {
        this.fieldEN.push({
          name: enFieldsArray[enFieldKey],
          key: enFieldKey,
        });
        this.mainfieldEN.push({
          name: enFieldsArray[enFieldKey],
          key: enFieldKey,
        });
      }
    },
    cityChange(i) {
      this.selectedCityKA = i;
      this.selectedCityEN = i;
      this.company.city = i;
    },
    fieldChange(i) {
      this.selectedFieldKA = i;
      this.selectedFieldEN = i;
      this.company.production_fields = i;
    },
    mainFieldChange(i) {
      this.selectedMainfieldKA = i;
      this.selectedMainfieldEN = i;
      this.company.main_production_field = i;
    },
    turnoverChanged(i) {
      this.company.yearly_turnover = i;
    },
    savecompany() {
      this.company.valid_phone = this.company.phoneVerified ? this.company.phone : 0;
      this.company.valid_email = this.company.emailVerified != "" ? this.company.email : "";

      console.log(this.company.message_locale)

      Api.company("update", this.company).then(() => {
        this.companyOriginal = Object.assign({}, this.company);
        this.reloadStats();
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    },
    resetcompany() {
      this.company = Object.assign({}, this.companyOriginal);
      this.selectedCityKA = this.companyOriginalCityKA;
      this.selectedCityEN = this.companyOriginalCityEN;
      this.selectedFieldKA = this.companyOriginalFieldsKA;
      this.selectedFieldEN = this.companyOriginalFieldsEN;
      this.selectedMainfieldKA = this.companyOriginalMainFieldKA;
      this.selectedMainfieldEN = this.companyOriginalMainFieldEN;
      this.selectedTurnover = this.companyOriginalTurnover;
    },
    reloadStats() {
      Api.company("get", this.$route.params.id).then(({ data }) => {
        this.loadData(data);
      });
    },
    loadData(data) {
      this.company = data;
      this.company.statistics.status = data.status;
      this.company.statistics.reg_date = data.reg_date;
      this.company.statistics.payed_to = data.payed_to;
      this.company.statistics.next_payment_amount = data.next_payment_amount;
      this.company.statistics.payment = {
        membership: 0,
        barcode: 0,
      };

      this.company.phoneVerified = false;
      this.company.emailVerified = false;

      if (this.company.valid_phone) {
        this.company.phoneVerified = true;
        this.company.phone = this.company.valid_phone;
      }
      if (this.company.valid_email != "") {
        this.company.emailVerified = true;
        this.company.email = this.company.valid_email;
      }

      data.payments.forEach(
        (e) => (this.company.statistics.payment[e.payment_type] += e.amount)
      );

      this.companyOriginal = Object.assign({}, data);

      this.selectedCityKA = data.city;
      this.companyOriginalCityKA = Object.assign({}, data.city);

      this.selectedCityEN = data.city;
      this.companyOriginalCityEN = Object.assign({}, data.city);

      this.selectedFieldKA = data.production_fields;
      this.companyOriginalFieldsKA = Object.assign({}, data.production_fields);

      this.companyOriginalMessageLocale = Object.assign({}, data.message_locale);

      this.selectedFieldEN = data.production_fields;
      this.companyOriginalFieldsEN = Object.assign({}, data.production_fields);

      this.selectedMainfieldKA = data.main_production_field;
      this.companyOriginalMainFieldKA = Object.assign(
        {},
        data.main_production_field
      );

      this.selectedMainfieldEN = data.main_production_field;
      this.companyOriginalMainFieldEN = Object.assign(
        {},
        data.main_production_field
      );

      this.selectedTurnover = data.yearly_turnover;
      this.companyOriginalTurnover = Object.assign({}, data.yearly_turnover);
      this.company.reg_id = this.company.reg_id == '/ne' ? '' : this.company.reg_id; 
      this.loadLists();
    },
  },
  beforeCreate() {
    Api.company("get", this.$route.params.id).then(({ data }) => {
      return this.loadData(data);
    });
  },
};
</script>
