export const validator = {
    hasError: true,
    barcode_count: {
        error_show: false,
        error_msg: null
    },
    amount: {
        error_show: false,
        error_msg: null
    },
    comment: {
        error_show: false,
        error_msg: null
    }
}

export const newItem = {
    barcode_count: null,
    amount: null,
    template: 'ka_gel',
    company_id: null,
    invoice_type: null,
    comment: null,
}